export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Kino Sprunked",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://wordle2.io/kino-sprunked.embed",
    domain: "kinosprunked.com",
    gaId: "G-V8239ZM23D",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
